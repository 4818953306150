import { PARTNER_API_BASE_URL } from 'services/connections.service';

const interpolatePath = ({ pathname, pathParams }) =>
  Object.entries(pathParams).reduce(
    (template, [key, value]) => template.replace(`:${key}`, value ?? ''),
    pathname
  );
const buildQueryString = ({ queryParams }) => {
  const queryString = new URLSearchParams(queryParams).toString();
  return queryString ? `?${queryString}` : '';
};

export const getURLPath = ({ pathname, pathParams = {}, queryParams = {} }) => {
  const interpolatedPath = interpolatePath({ pathname, pathParams });
  const queryString = buildQueryString({ queryParams });
  return `${interpolatedPath}${queryString}`;
};

const getAPIURL = ({
  baseUrl,
  pathname,
  pathParams = {},
  queryParams = {}
}) => {
  const path = getURLPath({ pathname, pathParams, queryParams });
  const url = new URL(`${baseUrl}${path}`);
  return url.toString();
};

const getPartnerAPIURL = (pathname, pathParams = {}) =>
  getAPIURL({ baseUrl: PARTNER_API_BASE_URL, pathname, pathParams });

export const VERIFY_OTP_WITH_MOBILE = getPartnerAPIURL('/login');

export const SEND_OTP = getPartnerAPIURL('/send-otp');

export const GET_ORDER_DETAIL = ({ partnerId, orderId }) =>
  getPartnerAPIURL('/partners/:partnerId/orders/:orderId/details', {
    partnerId,
    orderId
  });

export const GET_BID_DETAILS_BY_PARTNER_AND_REFERENCE_ID = ({
  partnerId,
  referenceId
}) =>
  getPartnerAPIURL('/partners/:partnerId/bid-references/:referenceId', {
    partnerId,
    referenceId
  });

export const UPDATE_BID_ITEM = (bidId, bidItemId) =>
  getPartnerAPIURL('/bid/:bidId/bid-item/:bidItemId', {
    bidId,
    bidItemId
  });

export const POST_CALLBACK_REQUEST_FOR_BID = (bidId) =>
  getPartnerAPIURL('/bids/:bidId/request-callback', { bidId });

export const SUBMIT_BID = (bidId) => getPartnerAPIURL('/bid/:bidId', { bidId });

export const GET_PARTNER_USER_PROFILE_DATA = () =>
  getPartnerAPIURL('/partner-users/detail');

export const CONTACT_US_FORM = () => getPartnerAPIURL('/partners/contact-us');

export const GET_PARTNER_SUMMARY_STATS_API = () =>
  getPartnerAPIURL('/summary-stats');

export const GUEST_CONTACT_US = getPartnerAPIURL('/contact-us');
